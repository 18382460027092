<template>
  <Header title="About Us" />

  <Container   class="bg-gray-50">
    <h2 class="mt-6 md:mt-2">Our History</h2>
    <p>
      Bryan and Hedden Financial was founded in 1975 as one of the area's first
      independent financial service companies. Our head office is located in
      Burlington, Ontario with partner offices extending across Canada.
      <br /><br />
      For almost 50 years, our team of insurance and financial agents have
      worked with business owners, corporate executives and professionals to
      address their concerns with respect to any one of a number of events that
      could affect the future well being of their business and personal wealth.
    </p>
    <h2 class="pt-6 md:mt-2">Mission Statement</h2>
    <p class="">
      Bryan and Hedden Financial is committed to being a trusted and credible
      provider of wealth management solutions and services by delivering
      personalized, relationship oriented service, and innovative
      solutions to business owners and professionals.
    </p>
  </Container>

  <!-- Section -->
  <!--<Container>
    <h2 class="text-center">Get in touch</h2>
    <p class="text-center mx-auto">
      Contact us to schedule a complimentary, private consultation or to request
      information.
    </p>
    <div class="relative max-w-xl mx-auto">
      <form
        action="#"
        method="POST"
        class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 mt-12"
      >
        <div>
          <label for="first-name">First name</label>
          <div class="mt-1">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autocomplete="given-name"
            />
          </div>
        </div>
        <div>
          <label for="last-name" class="block text-sm font-medium text-gray-700"
            >Last name</label
          >
          <div class="mt-1">
            <input
              type="text"
              name="last-name"
              id="last-name"
              autocomplete="family-name"
            />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email</label
          >
          <div class="mt-1">
            <input id="email" name="email" type="email" autocomplete="email" />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label
            for="phone-number"
            class="block text-sm font-medium text-gray-700"
            >Phone Number</label
          >
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              type="text"
              name="phone-number"
              id="phone-number"
              autocomplete="tel"
              class="
                phone-number
                py-3
                px-4
                block
                w-full
                border-gray-300
                rounded-md
              "
              placeholder="+1 (555) 987-6543"
            />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="message" class="block text-sm font-medium text-gray-700"
            >Message</label
          >
          <div class="mt-1">
            <textarea id="message" name="message" rows="4" />
          </div>
        </div>
        <div class="sm:col-span-2">
          <button
            type="submit"
            class="
              w-full
              inline-flex
              items-center
              justify-center
              px-6
              py-3
              border border-transparent
              rounded-md
              shadow-sm
              text-base
              font-medium
              text-white
              transition
              bg-accent-light
              hover:bg-accent-lightest hover:text-gray-800
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-accent
            "
          >
            Let's talk
          </button>
        </div>
      </form>
    </div>
  </Container>-->
  <!-- Our Team -->
  <Container>
    <h2 class="text-center mb-10">Our Team</h2>
    <div class="grid md:grid-cols-3 gap-standard">
      <ImageCard imageUrl="/img/John.jpg">John Hedden</ImageCard>
      <ImageCard imageUrl="/img/Ron.jpg">Ron Schmuck</ImageCard>
      <ImageCard imageUrl="/img/Lisa.jpg">Lisa Elley</ImageCard>
    </div>
	<div class="mt-14 text-center mb-14">
		<img src="@/assets/images/EPG-LOGO.png" class="mx-auto mb-8">
		<p>Independent member of the executive planning group with offices in: Vancouver, Edmonton, Toronto, Montreal and St. John's</p>
	</div>
    <div class="grid md:grid-cols-3 gap-standard">
      <ImageCard imageUrl="/img/John.jpg">John Hedden</ImageCard>
      <ImageCard imageUrl="/img/Herb.jpg">Herbert Braley, CLU</ImageCard>
      <ImageCard imageUrl="/img/Thomas.jpg">Thomas O'Neill</ImageCard>
    </div>
	
    <div class="grid md:grid-cols-6 gap-standard mt-8">
		<div class="col-span-1"></div>
      <ImageCard class="col-span-2" imageUrl="/img/Fred.jpg">Fred Humber, CLU</ImageCard>
      <ImageCard class="col-span-2" imageUrl="/img/Lew.jpg">Lew Warke, CLU</ImageCard>
		<div class="col-span-1"></div>
	</div>
  </Container>
</template>

<script>
import ImageCard from "../components/ImageCard.vue";
import Container from "../components/Container.vue";
import Header from "../components/Header.vue";


export default {
  components: { Header, Container, ImageCard },
};


</script>
